import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default class Under {
  constructor() {
    this.animeUnderTitle();
    this.swiper();
    this.animeMessage();
    this.helo();
    this.pageBackImage();
    window.addEventListener('resize', this.setValue);
  }
  setValue() {
    // title_border
    let fvLine = isWindowMin() ? 'calc(100/16 * 1rem)' : 'calc(195/16 * 1rem)';
    const fvLineTarget = document.querySelector('.underFv-line');
    if(fvLineTarget) {
      fvLineTarget.style.width = fvLine;
    }
    // message_line
    let messageLine = isWindowMin() ? 'calc(126/16 * 1rem)' : 'calc(195/16 * 1rem)';
    const messageLineTarget = document.querySelector('.page-message-heading-line');
    if(messageLineTarget) {
      messageLineTarget.style.width = messageLine;
    }
  }
  animeUnderTitle() {
    gsap.to('.underFv-line', {
      width: () =>
        isWindowMin() ? 'calc(100/16 * 1rem)' : 'calc(195/16 * 1rem)',
      duration: 0.7,
      delay: 1,
    });
    gsap.utils.toArray('.js-underFv-title-main').forEach((target) => {
      const targets = target.querySelectorAll('span');
      gsap.fromTo(
        targets,
        {
          color: 'rgba(255, 255, 255, 1)',
        },
        {
          color: 'rgba(255, 255, 255, 0)',
          duration: 2,
          delay: 1,
          stagger: 0.1,
        },
      );
    });
    gsap.fromTo(
      '.js-underFv-title-sub > span',
      {
        color: 'rgba(255, 255, 255, 1)',
      },
      {
        color: 'rgba(255, 255, 255, 0)',
        duration: 2.4,
        delay: 1,
        stagger: 0.1,
      },
    );
    gsap.fromTo(
      '.underFv',
      {
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
      {
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        duration: 2.5,
        delay: 1,
      },
    );
  }
  // 背景イメージ
  pageBackImage() {
    const image = document.querySelector('.page-backImage');
    if(image) {
      gsap.fromTo (image, {
        opacity: 1
      }, {
        opacity: 0,
        scrollTrigger: {
          trigger: '.page-imageWindow',
          toggleActions: 'play none none reverse',
          start: 'bottom top',
        },
      })
    }
  }
  // メッセージセクションアニメーション
  animeMessage() {
    gsap.utils
      .toArray('.page-message-heading')
      .forEach(() => {
        const text = document.querySelector('.page-message-heading');
        const messageTl = gsap.timeline({
          scrollTrigger: {
            trigger: text,
            start: 'top center',
          },
        });
        // リトリート
        messageTl
          .fromTo(
            text,
            {
              opacity: 0,
            },
            {
              opacity: 1,
              duration: 2,
            },
          )
          .to(
            '.page-message-heading-line',
            {
              width: () =>
                isWindowMin() ? 'calc(126/16 * 1rem)' : 'calc(195/16 * 1rem)',
              duration: 0.7,
              delay: 1.5,
            },
            '-=2.5',
          );
      });;
    gsap.fromTo(
      '.page-message-media .--inner img',
      {
        y: '0rem',
      },
      {
        y: '-4rem',
        scrollTrigger: {
          trigger: '.page-message-media .--inner figure',
          start: 'top bottom',
          end: 'bottom top',
          ease: 'none',
          scrub: true,
        },
      },
    );
    gsap.fromTo(
      '.page-message-gallery-1 img',
      {
        y: '0rem',
      },
      {
        y: '-5rem',
        scrollTrigger: {
          trigger: '.page-message-gallery-1',
          start: 'top bottom',
          end: 'bottom top',
          ease: 'none',
          scrub: true,
        },
      },
    );
    gsap.fromTo(
      '.page-message-gallery-2 img',
      {
        y: '0rem',
      },
      {
        y: '-3rem',
        scrollTrigger: {
          trigger: '.page-message-gallery-2',
          start: 'top bottom',
          end: 'bottom top',
          ease: 'none',
          scrub: true,
        },
      },
    );
    gsap.fromTo(
      '.page-message-gallery-3 img',
      {
        y: '0rem',
      },
      {
        y: '-3rem',
        scrollTrigger: {
          trigger: '.page-message-gallery-3',
          start: 'top bottom',
          end: 'bottom top',
          ease: 'none',
          scrub: true,
        },
      },
    );
    // 背景色変更
    gsap.fromTo(
      '.page-message-media.js-color',
      {
        backgroundColor: '#ffffff',
        color: '#172e3b',
      },
      {
        backgroundColor: '#1c2933',
        color: '#ffffff',
        duration: 0.5,
        scrollTrigger: {
          toggleActions: 'play reverse play reverse',
          trigger: '.page-message-media .js-colorTrrigger',
          endTrigger: '.swiper',
          start: 'top bottom+=30px',
          end: 'bottom bottom',
        },
      },
    );
    const buttonTarget = document.querySelectorAll('.button_text.--js-color')
    gsap.fromTo(
      '.page-message-media.js-color-2',
      {
        backgroundColor: '#ffffff',
        color: '#172e3b',
      },
      {
        backgroundColor: '#1c2933',
        color: '#ffffff',
        duration: 0.5,
        scrollTrigger: {
          toggleActions: 'play reverse play reverse',
          trigger: '.page-message-media .js-colorTrrigger-2',
          endTrigger: '.pageCource',
          start: () => isWindowMin() ? 'top center-=160' : 'top center',
          end: () => isWindowMin() ? 'top center-=160' : 'top bottom',
          toggleClass: {
            targets: buttonTarget,
            className: "is-active",
          },
        },
      },
    );
  }
  helo() {
    gsap.utils
      .toArray('.--helo')
      .forEach((target) => {
        const targteA = target.querySelector('.--text');
        const main = target.querySelectorAll('.--text span');
        const sub = target.querySelector('.--subText');
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: targteA,
            start: 'bottom bottom',
          },
        });
        tl
          .fromTo(main, {
            opacity: 0
          }, {
            opacity: 1,
            duration: 2,
            stagger: 0.1
          },'+=0.25'
          )
          .fromTo (sub, {
            opacity: 0
          }, {
            opacity: 1,
            duration: 2,
          },'-=1.75'
          );
      });
  }
  swiper() {
    if (isWindowMin()) {
      const swiper = new Swiper('.swiper', {
        loop: true, // ループ有効
        slidesPerView: 1.5, // 一度に表示する枚数
        speed: 7500, // ループの時間
        allowTouchMove: false, // スワイプ無効
        spaceBetween: 24,
        autoplay: {
          delay: 0, // 途切れなくループ
        },
      });
    } else {
      const swiper = new Swiper('.swiper', {
        loop: true, // ループ有効
        slidesPerView: 4, // 一度に表示する枚数
        speed: 15000, // ループの時間
        allowTouchMove: false, // スワイプ無効
        spaceBetween: 24,
        autoplay: {
          delay: 0, // 途切れなくループ
        },
      });
    }

    // swiper.autoplay.stop();
    // ScrollTrigger.create({
    //   trigger: ".page-imageWindow",
    //   start: "top bottom",
    //   end: "bottom center",
    //   // markers: true,
    //   onEnter: () => swiper.autoplay.start(),
    //   onEnterBack: () => swiper.autoplay.start(),
    //   onLeave: () => swiper.autoplay.stop(),
    //   onLeaveBack: () => swiper.autoplay.stop(),
    // });
  }
  // リサイズ
  resize() {
    var timer = 0;
    window.addEventListener('resize', function () {
      clearTimeout(timer);
      timer = setTimeout(function () {
      }, 1000);
    });
  }
}

// デバイスサイズ判定
function isWindowMin() {
  const h = window.innerWidth;
  if (h < 800) {
    return true;
  } else {
    return false;
  }
}
