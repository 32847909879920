import { Curtains, Plane, Vec2 } from "../../index.mjs";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);


const getPinPositionH = () => {
  let h = isSmartPhone() ? window.screen.height - 50 : window.innerHeight;
  return h;
}
const getPinPositionTop = () => {
  let h = isSmartPhone() ? window.screen.height - 50 : window.innerHeight;
  let target = document.querySelector(".js-pin-messageBg img");
  let top = "top " + (h - target.height) + "px";
  return top;
}
const getPinPositionBottom = () => {
  let h = isSmartPhone() ? window.screen.height - 50 : window.innerHeight;
  let bottom = "bottom " + h + "px";
  return bottom;
}

class Canvas {
  constructor() {
    // スマホ、タブレットの際はvh解除
    // this.jurnalW();
    window.addEventListener("DOMContentLoaded", () => {
      window.scroll({
        top: 0,
      });
      setFvHeight();
      this.resize();
      this.header();
    });

    //ロード完了後イベント開始
    window.addEventListener("load", () => {
      this.init();
      ScrollTrigger.refresh();
      this.textFade();
      this.message();
    });
  }
  jurnalW() {
    // const target = document.querySelector('.topJournal-cont');
    // const targetW = document.querySelector('.ly-inner');
    // const windowW = window.innerWidth;
    // const width = (windowW - targetW.clientWidth) / 2 + targetW.clientWidth;
    // target.style.width = width + 'px';
  }
  header() {
    const header =document.querySelector('header');
    header.style.visibility = 'hidden';
    header.style.opacity = 0;
    gsap.fromTo(header, {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: 0.5,
      scrollTrigger: {
        toggleActions: "play none none reverse",
        trigger: ".topMessage",
        start: "top top",
      },
    });
  }
  init() {
    // リサイズ処理
    var timer = 0;
    window.addEventListener("resize", function () {
      clearTimeout(timer);
      timer = setTimeout(function () {
      }, 1000);
    });
    // track the mouse positions to send it to the shaders
    const mousePosition = new Vec2();
    // we will keep track of the last position in order to calculate the movement strength/delta
    const mouseLastPosition = new Vec2();

    const deltas = {
      max: 0,
      applied: 0,
    };

    // set up our WebGL context and append the canvas to our wrapper
    const curtains = new Curtains({
      container: "canvas",
      watchScroll: false, // no need to listen for the scroll in this example
      pixelRatio: Math.min(1.5, window.devicePixelRatio), // limit pixel ratio for performance
    });

    // handling errors
    curtains
      .onError(() => {
        // we will add a class to the document body to display original images
        document.body.classList.add("no-curtains");
      })
      .onContextLost(() => {
        // on context lost, try to restore the context
        curtains.restoreContext();
      });

    // get our plane element
    const planeElements = document.getElementsByClassName("curtain");

    const vs = `
        precision mediump float;

        // default mandatory variables
        attribute vec3 aVertexPosition;
        attribute vec2 aTextureCoord;

        uniform mat4 uMVMatrix;
        uniform mat4 uPMatrix;
        
        // our texture matrix uniform
        uniform mat4 simplePlaneTextureMatrix;

        // custom variables
        varying vec3 vVertexPosition;
        varying vec2 vTextureCoord;

        uniform float uTime;
        uniform vec2 uResolution;
        uniform vec2 uMousePosition;
        uniform float uMouseMoveStrength;


        void main() {

            vec3 vertexPosition = aVertexPosition;

            // get the distance between our vertex and the mouse position
            float distanceFromMouse = distance(uMousePosition, vec2(vertexPosition.x, vertexPosition.y));

            // calculate our wave effect
            float waveSinusoid = cos(5.0 * (distanceFromMouse - (uTime / 75.0)));

            // attenuate the effect based on mouse distance
            float distanceStrength = (0.4 / (distanceFromMouse + 0.4));

            // calculate our distortion effect
            float distortionEffect = distanceStrength * waveSinusoid * uMouseMoveStrength;

            // apply it to our vertex position
            vertexPosition.z +=  distortionEffect / 30.0;
            vertexPosition.x +=  (distortionEffect / 30.0 * (uResolution.x / uResolution.y) * (uMousePosition.x - vertexPosition.x));
            vertexPosition.y +=  distortionEffect / 30.0 * (uMousePosition.y - vertexPosition.y);

            gl_Position = uPMatrix * uMVMatrix * vec4(vertexPosition, 1.0);

            // varyings
            vTextureCoord = (simplePlaneTextureMatrix * vec4(aTextureCoord, 0.0, 1.0)).xy;
            vVertexPosition = vertexPosition;
        }
    `;

    const fs = `
        precision mediump float;

        varying vec3 vVertexPosition;
        varying vec2 vTextureCoord;

        uniform sampler2D simplePlaneTexture;

        void main() {
            // apply our texture
            vec4 finalColor = texture2D(simplePlaneTexture, vTextureCoord);

            // fake shadows based on vertex position along Z axis
            finalColor.rgb -= clamp(-vVertexPosition.z, 0.0, 1.0);
            // fake lights based on vertex position along Z axis
            finalColor.rgb += clamp(vVertexPosition.z, 0.0, 1.0);

            // handling premultiplied alpha (useful if we were using a png with transparency)
            finalColor = vec4(finalColor.rgb * finalColor.a, finalColor.a);

            gl_FragColor = finalColor;
        }
    `;
    const h = window.innerHeight;
    const w = window.innerWidth;
    // some basic parameters
    const params = {
      vertexShader: vs,
      fragmentShader: fs,
      widthSegments: 20,
      heightSegments: 20,
      uniforms: {
        resolution: {
          // resolution of our plane
          name: "uResolution",
          type: "2f", // notice this is an length 2 array of floats
          value: [w, h],
        },
        time: {
          // time uniform that will be updated at each draw call
          name: "uTime",
          type: "1f",
          value: 0,
        },
        mousePosition: {
          // our mouse position
          name: "uMousePosition",
          type: "2f", // again an array of floats
          value: mousePosition,
        },
        mouseMoveStrength: {
          // the mouse move strength
          name: "uMouseMoveStrength",
          type: "1f",
          value: 0,
        },
      },
    };
    // インターバル
    let interval = 0;

    // create our plane
    const simplePlane = new Plane(curtains, planeElements[0], params);
    simplePlane.scale.y = 1.1;
    simplePlane.scale.x = 1.1;

    // if there has been an error during init, simplePlane will be null
    simplePlane
      .onReady(() => {
        // set a fov of 35 to reduce perspective (we could have used the fov init parameter)
        simplePlane.setPerspective(35);

        // apply a little effect once everything is ready
        deltas.max = 7;

        // now that our plane is ready we can listen to mouse move event
        const wrapper = document.getElementById("page-wrap");

        // wrapper.addEventListener("mousemove", (e) => {
        //   handleMovement(e, simplePlane);
        // })
      })
      .onRender(() => {
        // increment our time uniform
        // simplePlane.uniforms.time.value++;
        simplePlane.uniforms.time.value += 2 - interval;
        if (interval < 1.6) {
          interval += 0.0125;
        }

        // decrease both deltas by damping : if the user doesn't move the mouse, effect will fade away
        deltas.applied += (deltas.max - deltas.applied) * 0.02;
        if (deltas.max > 3) {
          // deltas.max += (0 - deltas.max) * 0.05;
          deltas.max += (0 - interval) * 0.05;
        }

        // send the new mouse move strength value
        simplePlane.uniforms.mouseMoveStrength.value = deltas.applied;
      })
      .onAfterResize(() => {
        const planeBoundingRect = simplePlane.getBoundingRect();
        simplePlane.uniforms.resolution.value = [
          planeBoundingRect.width,
          planeBoundingRect.height,
        ];
      })
      .onError(() => {
        // we will add a class to the document body to display original images
        document.body.classList.add("no-curtains");
      });
  }
  resize() {
    window.addEventListener('resize',() => {
      const target = document.querySelector('.js-message-line.is-animated');
      // メッセージのラインの長さ
      let lineHeight = isWindowMin() ? "calc(167/16 * 1rem)" : "calc(288/16 * 1rem)";
      if(target) {
        target.style.width = lineHeight;
      }
    })
  }
  textFade() {
    gsap.to(".js-titleFadeMain > span", {
      opacity: 1,
      filter: "blur(0px)",
      duration: 2,
      delay: 1,
      stagger: 0.1,
    });
    gsap.to(".js-titleFadeSub > span", {
      opacity: 1,
      filter: "blur(0px)",
      duration: 2,
      delay: 1.6,
      stagger: 0.01,
    });
    gsap.to(".js-title-line", {
      width: "calc(100/16 * 1rem)",
      duration: 0.3,
      delay: 2.4,
    });
    const messageLine = document.querySelector(".js-message-line")
    gsap.to(".js-message-line", {
      width: isWindowMin() ? "calc(167/16 * 1rem)" : "calc(288/16 * 1rem)",
      duration: 0.85,
      // delay: 3.4,
      scrollTrigger: {
        trigger: ".js-message-line",
        start: "top bottom-=250px",
        onEnter() {
          messageLine.classList.add('is-animated');
        },
      },
    });
    gsap.fromTo(
      ".kvTitle",
      {
        opacity: 1,
      },
      {
        opacity: 0,
        scrollTrigger: {
          trigger: ".topMessage",
          start: "top bottom-=20px",
          end: "top top",
          ease: "none",
          scrub: true,
        },
      }
    );  
    let mm = gsap.matchMedia();
    mm.add("(min-width: 801px)", () => {
      gsap.fromTo(
        ".js-topProfile img",
        {
          y: '0rem',
        },
        {
          y: "4rem",
          scrollTrigger: {
            trigger: ".ly-profile",
            start: "top bottom",
            end: "bottom top",
            ease: "none",
            scrub: true,
          },
        }
      );
    });
  }
  message() {
    gsap.to(".js-message-sub > span", {
      autoAlpha: 1,
      duration: 2,
      stagger: 0.07,
      delay: isWindowMin() ? 1 : 0,
      scrollTrigger: {
        trigger: ".topMessage",
        start: "top center",
      },
    });
    gsap.to(".js-message-main > span", {
      opacity: 1,
      duration: 2,
      stagger: 0.15,
      delay: isWindowMin() ? 0 : 1,
      scrollTrigger: {
        trigger: ".topMessage",
        start: "top center",
      },
    });
    gsap.utils.toArray(".message-desc p").forEach((target) => {
      // messageフェードイン
      gsap.fromTo(
        target,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1.5,
          scrollTrigger: {
            trigger: target,
            start: "bottom bottom",
          },
        }
      );
    });
    // 追従バックグラウンド
    // ヘッダーの高さの指定

    gsap.fromTo(
      ".js-pin-messageBg",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 3,
        scrollTrigger: {
          trigger: ".js-pin-messageBg",
          start:()=> "top center-=" + getPinPositionH() / 3 + "px",
        },
      }
    );
    gsap.to(".js-pin-messageBg", {
      scrollTrigger: {
        trigger: ".js-pin-messageBg",
        start: () => getPinPositionTop(),
        end: () => getPinPositionBottom(),
        // start: top,
        // end: bottom,
        endTrigger: ".js-pin-message",
        pin: true,
        anticipatePin: 1,
      },
    });
    gsap.fromTo(
      ".js-message-pararacs01 img",
      {
        y: "0rem",
      },
      {
        y: "-4rem",
        scrollTrigger: {
          trigger: ".message-photo",
          start: "top center",
          end: "bottom top",
          // endTrigger: ".message-photo",
          ease: "none",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      ".js-message-pararacs02 img",
      {
        y: "0rem",
      },
      {
        y: "-4rem",
        scrollTrigger: {
          trigger: ".message-photo",
          start: "top center",
          end: "bottom top",
          // endTrigger: ".message-photo",
          ease: "none",
          scrub: true,
        },
      }
    );
    gsap.fromTo(
      ".js-message-pararacs03 img",
      {
        y: "0rem",
      },
      {
        y: "-4rem",
        scrollTrigger: {
          trigger: ".message-photo",
          start: "top center",
          end: "bottom bottom",
          // endTrigger: ".message-photo",
          ease: "none",
          scrub: true,
        },
      }
    );
  }
}

// 関数
// デバイスサイズ付与
function setFvHeight() {
  const h = window.innerHeight;
  const canvas = document.querySelector("#canvas");
  const wrap = document.querySelector("#page-wrap");
  canvas.style.height = h + "px";
  wrap.style.height = h + "px";
}
// スマホ・タブレット判定
function isSmartPhone() {
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
    return true;
  } else {
    return false;
  }
}
// デバイスサイズ判定
function isWindowMin() {
  const h = window.innerWidth;
  if (h < 800) {
    return true;
  } else {
    return false;
  }
}

export default Canvas;
