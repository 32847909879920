class SetFillHeight {
  constructor() {
    this.vw = window.innerWidth;
  }
  init() {
    this.setProperty();
    this.on();
    this.addEvent();
  }
  addEvent() {
    this.vw = window.innerWidth;

    if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)) {
      window.addEventListener('orientationchange', this.on.bind(this));
    } else {
      window.addEventListener('resize', this.on.bind(this));
    }
  }
  on() {
    if (this.vw === window.innerWidth) return;

    this.vw = window.innerWidth;
    this.setProperty();
  }
  setProperty() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}

export default SetFillHeight;
