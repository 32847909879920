import gsap from "gsap";
class Components {
    constructor() {
        document.addEventListener('DOMContentLoaded', () => {
          this.modal = document.querySelectorAll('.videoModal_cont');
          this.modalOpen = document.querySelectorAll('.videoModal_modal');
          this.modalClose = document.querySelectorAll('.videoModal_close');
          this.modalOverlay = document.querySelector('.videoModal_overlay');
        });
        
        document.addEventListener('DOMContentLoaded', () => {
            this.videoModal();
        });
    }
    videoModal() {
        const modalOpenButton = this.modalOpen;
        const modalCloseButton = this.modalClose;

        if(modalOpenButton.length) {
            let target = '';
            modalOpenButton.forEach((button) => {
                button.addEventListener('click', () => {
                    target = button.getAttribute('data-modal');
                    modalOpen(target, origin);
                });
            });
            modalCloseButton.forEach((button) => {
                button.addEventListener('click', () => {
                    modalClose(target, origin);
                    target = '';
                });
            });
        }
        const modalOpen = (target) => {
            Array.from(this.modal).forEach((modal) => {
                const itemData = modal.getAttribute('data-modal_item');
                if(itemData === target) {
                    modalFadeIn(modal);
                    const video = modal.querySelector('video');
                    video.load();
                    video.play();
                }
            });
        }
        const modalClose = (target) => {
            Array.from(this.modal).forEach((modal) => {
                const itemData = modal.getAttribute('data-modal_item');
                if(itemData === target) {
                    const video = modal.querySelector('video');
                    video.pause();
                    modalFadeOut(modal);
                }
            });
        }
        const modalFadeIn = (target) => {
            gsap.fromTo(target, {
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                duration: 0.6,
            });
            gsap.fromTo(this.modalOverlay, {
                autoAlpha: 0,
            }, {
                autoAlpha: 1,
                duration: 0.6,
            });
        }
        const modalFadeOut = (target) => {
            gsap.fromTo(target, {
                autoAlpha: 1,
            }, {
                autoAlpha: 0,
                duration: 0.6,
            });
            gsap.fromTo(this.modalOverlay, {
                autoAlpha: 1,
            }, {
                autoAlpha: 0,
                duration: 0.6,
            });
        }
    }
}
export default Components;