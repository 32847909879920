import SetFillHeight from './setFillHeight';

class Config {
  constructor() {
    this.setFillHeight = new SetFillHeight();
  }
  init() {
    this.setFillHeight.init();
  }
}

export default Config;
