import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default class Under {
  constructor() {
    this.checkbox();
  }
  // リサイズ
  resize() {
    var timer = 0;
    window.addEventListener('resize', function () {
      clearTimeout(timer);
      timer = setTimeout(function () {
      }, 1000);
    });
  }
  checkbox() {
    var flag = false; // 選択されているか否かを判定する変数
    const input = document.querySelector('input[type="checkbox"]');
    const target = document.querySelector('.checkbox_dummy');
    const classAdded = document.querySelector('.checkbox_text');

    if (input.checked) {
      flag = true;
    }
    if (flag == true) {
      target.classList.add('is-active');
    }

    target.addEventListener('click', function() {
      target.classList.toggle('is-active');
    });
    classAdded.addEventListener('click', function() {
      target.classList.toggle('is-active');
    });
  }
}

// デバイスサイズ判定
function isWindowMin() {
  const h = window.innerWidth;
  if (h < 800) {
    return true;
  } else {
    return false;
  }
}
