import Config from './utils/config';
import Page06 from './06_shader_texture';
import Under from './07_Page';
import Common from './common';
import Lenis from '@studio-freight/lenis';
import Contact from './08_Contact';
import Components from './components';

class App {
  constructor() {
    this.config = new Config();
    new Components();
  }
  render() {
    this.config.init();
    document.addEventListener('DOMContentLoaded', () => {
      const path = location.pathname;

      const lenis = new Lenis({
        smoothTouch: true, // タッチイベントでも慣性スクロールを有効にする
      });
      function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }
      requestAnimationFrame(raf);

      new Common();
      if (
        path === '/rie_fujiwara/' ||
        path === '/rie_fujiwara/en' ||
        path === '/rie_fujiwara/en/' ||
        path === '/' ||
        path === '/en' ||
        path === '/en/'
      ) new Page06();
      if (
        path === '/retreat' ||
        path === '/inner-peace' ||
        path === '/hypnotherapy' ||
        path === '/retreat/' ||
        path === '/inner-peace/' ||
        path === '/hypnotherapy/' ||
        path === '/rie_fujiwara/retreat' ||
        path === '/rie_fujiwara/inner-peace' ||
        path === '/rie_fujiwara/hypnotherapy' ||
        path === '/en/inner-peace' ||
        path === '/en/inner-peace/' ||
        path === '/rie_fujiwara/en/inner-peace'
      )
        new Under();
      if (
        path === '/contact' ||
        path === '/contact/confirm' ||
        path === '/contact/complete' ||
        path === '/contact/news' ||
        path === '/privacypolicy' ||
        path === '/en/contact' ||
        path === '/en/contact/confirm' ||
        path === '/en/contact/complete' ||
        path === '/en/contact/news' ||
        path === '/en/privacypolicy' ||
        path === '/contact/' ||
        path === '/contact/confirm/' ||
        path === '/contact/complete/' ||
        path === '/contact/news/' ||
        path === '/privacypolicy/' ||
        path === '/en/contact/' ||
        path === '/en/contact/confirm/' ||
        path === '/en/contact/complete/' ||
        path === '/en/contact/news/' ||
        path === '/en/privacypolicy/' ||
        path === '/rie_fujiwara/contact' ||
        path === '/rie_fujiwara/contact/confirm' ||
        path === '/rie_fujiwara/contact/complete' ||
        path === '/rie_fujiwara/contact/news' ||
        path === '/rie_fujiwara/privacypolicy' ||
        path === '/rie_fujiwara/en/contact' ||
        path === '/rie_fujiwara/en/contact/confirm' ||
        path === '/rie_fujiwara/en/contact/complete' ||
        path === '/rie_fujiwara/en/contact/news' ||
        path === '/rie_fujiwara/en/privacypolicy'
      )
        new Contact();
    });
  }
}

export default App;
