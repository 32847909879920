import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

class Common {
  constructor() {
    const body = document.querySelector("body");
    this.textSplit();
    this.setCurrent();
    this.resize();
    if(!isSmartPhone()) {
      this.mouseMove();
    }

    // ロード後イベント
    window.addEventListener("load", () => {
      ScrollTrigger.refresh();
      body.style.opacity = 1;
      this.animation();
      this.header();
      this.animeContactTitle();
    });
    window.addEventListener('resize', this.setValue);
  }
  mouseMove() {
    window.addEventListener("DOMContentLoaded", () => {
      const follower = document.getElementById("follower");
      let pos = { x: 0, y: 0 };
      let mouse = { x: pos.x, y: pos.y };
      let link = document.querySelectorAll("a:not(.--active):not(.--noaction)");
      let speed = 0.3; // 0.01〜1 数値が大きほど早い
      // カーソルの座標を設定する
      // 遅延するカーソルの座標を設定する
      var followerSetX = gsap.quickSetter(follower, "x", "px");
      var followerSetY = gsap.quickSetter(follower, "y", "px");
      // マウスカーソル座標を取得する
      const getMousePosition = (event) => {
        mouse.x = event.clientX;
        mouse.y = event.clientY;
      }
      document.addEventListener("mousemove", function(event) {getMousePosition(event)});
      gsap.ticker.add(function() {
        var dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
        
        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;
        followerSetX(pos.x);
        followerSetY(pos.y);
      });
      // マウスイベント
      for(let i = 0; i < link.length; i++) {
        link[i].addEventListener("mouseenter", function() {
          follower.classList.add("is-active");
        });
        link[i].addEventListener("mouseleave", function() {
          follower.classList.remove("is-active");
        });
      }
      // ボタン
      const buttonTarget = document.querySelectorAll("button");
      for(let i = 0; i < buttonTarget.length; i++) {
        buttonTarget[i].addEventListener("mouseenter", function() {
          follower.classList.add("is-active");
        });
        buttonTarget[i].addEventListener("mouseleave", function() {
          follower.classList.remove("is-active");
        });
      }
      // ヘッダーボタン
      const headTarget = document.querySelector("header figure");
      headTarget.addEventListener("mouseenter", function() {
        follower.classList.add("is-active");
      });
      headTarget.addEventListener("mouseleave", function() {
        follower.classList.remove("is-active");
      });
    })
  }
  resize() {
    window.addEventListener('resize',() => {
      const target = document.querySelectorAll('.js-heading-line.is-animated');
      let lineHeight = isWindowMin() ? "calc(95/16 * 1rem)" : "calc(200/16 * 1rem)";
      // メッセージのラインの長さ
      target.forEach((t) => {
        if(t) {
          t.style.width = lineHeight;
        }
      });
    })
  }
  setCurrent() {
    const targets = document.querySelectorAll(".footerRightInner a");
    targets.forEach((target)=>{
      if(target.href === location.href) {
        target.classList.add('--active');
      }
    });
  }
  setValue() {
    // title_border
    let fvLine = isWindowMin() ? 'calc(100/16 * 1rem)' : 'calc(195/16 * 1rem)';
    const fvLineTarget = document.querySelector('.contactFv-line');
    if(fvLineTarget) {
      fvLineTarget.style.width = fvLine;
    }
  }
  // 下層FVアニメ
  animeContactTitle() {
    gsap.to('.contactFv-line', {
      width: () =>
        isWindowMin() ? 'calc(100/16 * 1rem)' : 'calc(195/16 * 1rem)',
      duration: 0.7,
      delay: 1,
    });
    
    function isSinglePage() {
      const target = document.querySelector('.contactFv');
      if (target.classList.contains('--singlePage')) {
        return true;
      } else {
        return false;
      }
    }

    gsap.utils.toArray('.js-contactFv-title-main').forEach((target) => {
      const targets = target.querySelectorAll('span');
      gsap.fromTo(
        targets,
        {
          color: 'rgba(255, 255, 255, 1)',
        },
        {
          color: 'rgba(255, 255, 255, 0)',
          duration: 2,
          delay: 1,
          stagger: () => isSinglePage() ? 0 : 0.1,
        },
      );
    });
    gsap.fromTo(
      '.js-contactFv-title-sub > span',
      {
        color: 'rgba(255, 255, 255, 1)',
      },
      {
        color: 'rgba(255, 255, 255, 0)',
        duration: 2.4,
        delay: 1,
        stagger: 0.1,
      },
    );
    gsap.fromTo(
      '.contactFv',
      {
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
      {
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        duration: 2.5,
        delay: 1,
      },
    );
  }
  header() {
    const header =document.querySelector('header');
    const target = document.querySelector("header figure");
    const win = document.querySelector(".header-window");

    win.style.visibility ='hidden';
    win.style.opacity = 0;

    target.addEventListener("click", () => {
      if(header.classList.contains("is-active")) {
        gsap.fromTo( win, {
          autoAlpha: 1,
        },{
          autoAlpha: 0,
          duration: 0.6,
        });
        header.classList.remove('is-active');
      } else {
        gsap.fromTo( win, {
          autoAlpha: 0,
        },{
          autoAlpha: 1,
          duration: 0.6,
        });
        header.classList.add('is-active');
      }
    });
  }
  animation() {
    gsap.utils.toArray(".js-heading-text").forEach((target) => {
      const targetTxt = target.querySelectorAll("span");
      gsap.fromTo(
        targetTxt,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 2,
          // delay: 1.6,
          stagger: 0.1,
          scrollTrigger: {
            trigger: target,
            start: "bottom bottom",
          },
        }
      );
    });
    let mm = gsap.matchMedia();
    mm.add("(min-width: 801px)", () => {
      gsap.fromTo(
        '.js-footerInner',
        {
          y: () => '-10rem',
        },
        {
          y: 0,
          scrollTrigger: {
            trigger: 'footer',
            start: "top bottom",
            end: "bottom bottom",
            ease: "none",
            scrub: true,
          },
        }
      );
    })
    gsap.utils.toArray(".js-heading-line").forEach((target) => {
      gsap.to(target, {
        width: () => isWindowMin() ? "calc(95/16 * 1rem)" : "calc(200/16 * 1rem)",
        duration: 0.85,
        // delay: 3.4,
        scrollTrigger: {
          trigger: target,
          start: "top+=250px bottom",
          onEnter() {
            target.classList.add('is-animated');
          },
        },
      });
    });
  }
  textSplit() {
    const titles = document.querySelectorAll(".js-split");
    titles.forEach((child) => {
      const texts = child.textContent.split("");
      child.textContent = "";
      let output = "";
      texts.forEach(
        (text) => (output += text === " " ? " " : `<span>${text}</span>`)
      );
      child.innerHTML = output;
    });
  }
}
// スマホ・タブレット判定
function isSmartPhone() {
  if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
    return true;
  } else {
    return false;
  }
}
// デバイスサイズ判定
function isWindowMin() {
  const h = window.innerWidth;
  if (h < 801) {
    return true;
  } else {
    return false;
  }
}

export default Common;
